import "./menu.css";

document.addEventListener(
  "DOMContentLoaded",
  () => {
    const burger = document.getElementById("menu_burger");
    const menu = document.getElementById("menu");

    burger.addEventListener("click", () => {
      menu.classList.toggle("menu_opened");
    });
  },
  false
);
