import "./gallery.css";
import "./slider.css";

import Splide from "@splidejs/splide";

document.addEventListener(
  "DOMContentLoaded", //"turbolinks:load", // doesn't work
  () => {
    new Splide(`#gallery`, {
      padding: 0,
      gap: 0,
      perPage: 1,
      arrows: false,
      speed: 850,
      autoplay: true,
      interval: 3000,
      rewind: true,
    }).mount();
  },
  false
);
